import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import Faqs from '../../components/Faqs';
import ContactOnlineBanking from '../../components/ContactOnlineBanking';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import HeroBanner from '@cof/graffiti-alley-spray-cans/molecules/HeroBanner';
import Accordion from '@cof/graffiti-alley-spray-cans/molecules/Accordion';
import AccordionSection from '@cof/graffiti-alley-spray-cans/molecules/Accordion/AccordionSection';
import { callUsAnchorModalOpen } from '../../lib/utils';
import './contact-us-page.scss';

const CapitalOneCards = injectIntl(({ intl, location }) => {
  useEffect(() => {
    callUsAnchorModalOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.location?.href]);
  return (
    <Layout intl={intl} location={location} pageName="contact-capitalonecards-page" className="contact-capitalonecards">
      <div className="page-content-wrapper">
        <HeroBanner backgroundContentClass="page-hero-banner" mobileFriendly>
          <div className="content-wrapper">
            <p className="as-h1">
              <FormattedMessage id={'hero.contact-us.title'} />
            </p>
          </div>
        </HeroBanner>
        <div className="page-content">
          <ContactOnlineBanking />
          <header>
            <h2 id="faq-header">
              <FormattedMessage id="pages.contact-us.check-out-faq-title" />
            </h2>
          </header>
          <div id="capitalonecards-faq">
            <Faqs brand="capitalonecards" />
          </div>
          <div className="more-faq">
            <Link to={intl.formatMessage({ id: 'pages.contact-us.see_more_faq-link' })}>
              <i className="oi-question outline"></i>
              <span>
                <FormattedMessage id="pages.contact-us.see_more_faq" />
              </span>
            </Link>
          </div>

          <div className="contact-accordion">
            <Accordion startingIndex={5}>
              <AccordionSection title={intl.formatMessage({ id: 'pages.contact-us.call_us' })}>
                <article className="call-us-container">
                  <header>
                    <h6 className="section-header">
                      <FormattedMessage id="pages.contact-us.customer-service" />
                    </h6>
                  </header>
                  <section className="call-us-contents">
                    <div>
                      <FormattedMessage id="pages.contact-us.toll-free-within-canada" />
                      <div>
                        <strong>1-800-481-3239</strong>
                      </div>
                    </div>
                    <div>
                      <FormattedMessage id="pages.contact-us.call-collect" />
                      <div className="bold-text">
                        <strong>1-804-934-2010</strong>
                      </div>
                    </div>
                    <div>
                      <FormattedMessage id="pages.contact-us.tty" />
                      <div>
                        <FormattedMessage id="pages.contact-us.tty-info" />
                      </div>
                    </div>
                    <div>
                      <FormattedMessage id="pages.contact-us.application-status" />
                      <div className="bold-text">
                        <strong>1-877-277-5901</strong>
                      </div>
                      {intl.locale === 'en' && (
                        <div className="extra-info">
                          <p className="bold-text">
                            <FormattedMessage id="pages.contact-us.application-status-info" />
                          </p>
                        </div>
                      )}
                    </div>
                  </section>
                </article>
              </AccordionSection>
              <AccordionSection title={intl.formatMessage({ id: 'pages.contact-us.mail_us' })}>
                <div className="mail-us-container">
                  <div className="mail-us-payments">
                    <header>
                      <h6 className="section-header">
                        <FormattedMessage id="pages.contact-us.payments" />
                      </h6>
                    </header>
                    <section className="mail-us-contents">
                      <div>
                        <FormattedMessage id="pages.contact-us.mail-payments-to" />
                      </div>
                      <div>
                        <div>
                          <div className="bold-text">
                            <FormattedMessage id="pages.contact-us.payments-to.title" />
                          </div>
                          <div>
                            <FormattedMessage id="pages.contact-us.payments-to.address_line1" />
                          </div>
                          <div>
                            <FormattedMessage id="pages.contact-us.payments-to.address_line2" />
                          </div>
                        </div>
                        <p className="fine-text">
                          <FormattedMessage id="pages.contact-us.payments-to.info" />
                        </p>
                      </div>
                    </section>
                  </div>

                  <div className="mail-us-correpondence">
                    <header>
                      <h6 className="section-header">
                        <FormattedMessage id="pages.contact-us.general-correspondence" />
                      </h6>
                    </header>
                    <section className="mail-us-contents">
                      <div>
                        <div className="bold-text">
                          <FormattedMessage id="pages.contact-us.capone-customer-service.title" />
                        </div>
                        <div>
                          <FormattedMessage id="pages.contact-us.capone-customer-service.address_line1" />
                        </div>
                        <div>
                          <FormattedMessage id="pages.contact-us.capone-customer-service.address_line2" />
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="mail-us-payments-investigations">
                    <header className="payment-investigations-header">
                      <h6>
                        <FormattedMessage id="pages.contact-us.payment-investigations.title" />
                      </h6>
                      <p>
                        <FormattedMessage id="pages.contact-us.payment-investigations.info" />
                      </p>
                    </header>
                    <section className="mail-us-contents">
                      <div>
                        <div className="bold-text">
                          <FormattedMessage id="pages.contact-us.payment-investigations.to" />
                        </div>
                        <div>
                          <FormattedMessage id="pages.contact-us.payment-investigations.attn" />
                        </div>
                        <div>
                          <FormattedMessage id="pages.contact-us.payment-investigations.address_line1" />
                        </div>
                        <div>
                          <FormattedMessage id="pages.contact-us.payment-investigations.address_line2" />
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </AccordionSection>
            </Accordion>
          </div>
          <section>
            <div id="contact-us-addressing-and-privacy-content" className="grid-x">
              <div id="contact-us-addressing-concerns" className="cell large-6 contact-us-addressing-concerns-link">
                <Link to="/contact-us/concerns/">
                  <i className="oi-info outline"></i>
                  <span>
                    <FormattedMessage id="pages.contact-us.addressing_your_concerns" />
                  </span>
                </Link>

                <div id="contact-us-addressing-concerns-subtitle">
                  <p>
                    <FormattedMessage id="pages.contact-us.addressing_concerns_subtitle" />
                  </p>
                </div>
              </div>

              <div id="contact-us-privacy" className="cell large-6 contact-us-privacy-link">
                <Link to="/privacypolicy/">
                  <i className="oi-locked outline"></i>
                  <span>
                    <FormattedMessage id="pages.contact-us.privacy" />
                  </span>
                </Link>

                <div id="contact-us-privacy-subtitle" className="contact-us-subtitle">
                  <p>
                    <FormattedMessage id="pages.contact-us.privacy_subtitle" />
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
});

export default CapitalOneCards;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.contact-us.seo.capitalonecards.page-title"
    descriptionKey="pages.contact-us.seo.capitalonecards.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
