import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Accordion from '@cof/graffiti-alley-spray-cans/molecules/Accordion';
import AccordionSection from '@cof/graffiti-alley-spray-cans/molecules/Accordion/AccordionSection';
import { capitalOneCardsKeywords, consumerAdvocacyKeywords } from './FaqSettings';
import { superScript } from '../../lib/utils';

const Faqs = injectIntl(({ intl, pageName = 'contact-us', brand, listName = 'faq' }) => {
  const brandChoices = {
    capitalonecards: { faqsCount: 5, keywordTranslations: capitalOneCardsKeywords(intl) },
    'consumer-advocacy': { faqsCount: 4, keywordTranslations: consumerAdvocacyKeywords(intl) },
  };
  const { faqsCount, keywordTranslations } = brandChoices[brand];
  return (
    <Accordion>
      {Array(faqsCount)
        .fill()
        .map((_, i) => {
          const faq = (
            <FormattedMessage
              id={`pages.${pageName}.${brand}.${listName}${i + 1}.question`}
              values={{ md: superScript('md'), rball: superScript('rball') }}
            />
          );
          return (
            <AccordionSection key={i} title={faq}>
              <div className="faq-answer">
                <FormattedMessage
                  id={`pages.${pageName}.${brand}.${listName}${i + 1}.answer`}
                  values={{ ...keywordTranslations }}
                />
              </div>
            </AccordionSection>
          );
        })}
    </Accordion>
  );
});

Faqs.propTypes = {
  brand: PropTypes.oneOf(['capitalonecards', 'consumer-advocacy']).isRequired,
};

Faqs.defaultProps = {
  brand: 'capitalonecards',
};

Faqs.displayName = 'Faqs';

export default Faqs;
