import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';

const ContactOnlineBanking = injectIntl(({ intl }) => {
  const signingInToOnlineBanking = (
    <Link to="/sign-in/" target="_blank" rel="noopener">
      <FormattedMessage id="pages.contact-us.signing-in-to-online-banking" />
    </Link>
  );
  const usingTheMobileApp = (
    <Link to="/app/" target="_blank" rel="noopener">
      <FormattedMessage id="pages.contact-us.using-the-mobile-app" />
    </Link>
  );
  const goToOnlineBanking = (
    <Link to="/sign-in/" target="_blank" rel="noopener">
      <FormattedMessage id="pages.contact-us.go-to-online-banking" />
    </Link>
  );

  return (
    <section>
      <div id="contact-us-self-serve-text-1">
        <p className="bold-text">
          <FormattedMessage id="pages.contact-us.sub-title" />
        </p>
        <p>
          <FormattedMessage
            id="pages.contact-us.self-serve-text"
            values={{ signingInToOnlineBanking, usingTheMobileApp }}
          />
        </p>
      </div>
      <section id="contact-us-self-serve-options">
        <div id="contact-us-self-serve-options-content" className={`lang-${intl.locale}`}>
          <div className="text-button-container">
            <div className="column-container">
              <p className="bold-text">
                <FormattedMessage id="pages.contact-us.self-serve-option-sign-in" values={{ goToOnlineBanking }} />
              </p>
            </div>
            <Link id="sign-in-button" className="button progressive" to="/sign-in/" target="_blank" rel="noopener">
              <FormattedMessage id={'pages.contact-us.sign-in'} />
            </Link>
          </div>
          <div className="text-button-container download-button-container">
            <div>
              <p className="bold-text">
                <FormattedMessage id="pages.contact-us.self-serve-option-mobile" />
              </p>
            </div>
            <Link id="download-now-button" className="button progressive" to="/app/" target="_blank" rel="noopener">
              <FormattedMessage id="pages.contact-us.download-now" />
            </Link>
          </div>
        </div>
      </section>
    </section>
  );
});

ContactOnlineBanking.displayName = 'ContactOnlineBanking';

export default ContactOnlineBanking;
