import React from 'react';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import { FormattedMessage } from 'react-intl';
import { withPrefix } from 'gatsby';
import { superScript, callUsAnchorModalOpen } from '../../lib/utils';
import newrelicPageAction from '../../lib/newrelicSpa';

const createList = ({ prefix, size, siblings, isNumerical, values }) => {
  const elements = [];
  for (let i = 0; i < size; i++) {
    elements.push(
      <li key={`${prefix}${i}`}>
        <FormattedMessage id={`${prefix}${i}`} values={values?.[i] ?? {}} />
        {siblings && siblings[i]}
      </li>,
    );
  }
  return isNumerical ? <ol>{elements}</ol> : <ul>{elements}</ul>;
};

export const capitalOneCardsKeywords = (intl) => {
  const signInLink = (
    <Link
      to={intl.formatMessage({ id: 'pages.contact-us.capitalonecards.sign-in-link' })}
      target="_blank"
      rel="noopener"
      onClick={() => newrelicPageAction('EaseLogin', { faq: 'dispute' })}
    >
      <FormattedMessage id="pages.contact-us.capitalonecards.sign-in-link-text" />
    </Link>
  );

  return {
    contactUsLink: (
      <Link
        onClick={() => {
          callUsAnchorModalOpen();
        }}
        to="/contact-us/capitalonecards/#label-acc-5"
        target="_self"
      >
        <FormattedMessage id="pages.contact-us.capitalonecards.contact-us-link-text" />
      </Link>
    ),
    howToPay: (
      <>
        <p className="bold-text top-margin">
          <FormattedMessage id="pages.contact-us.capitalonecards.how-to-pay.title" />
        </p>
        {createList({
          prefix: 'pages.contact-us.capitalonecards.how-to-pay.step',
          size: 3,
          isNumerical: true,
        })}
      </>
    ),
    disputeSteps: (
      <>
        {createList({
          prefix: 'pages.contact-us.capitalonecards.dispute-steps.step',
          size: 4,
          values: { 0: { signInLink } },
          isNumerical: true,
        })}
      </>
    ),
  };
};

export const consumerAdvocacyKeywords = (intl) => {
  const viewCode = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.view-code-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.view-code" />
    </Link>
  );
  const seniorsCode = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.seniors-code-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.seniors-code" />
    </Link>
  );
  const viewCommitment = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.view-commitment-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.view-commitment" />
    </Link>
  );
  const viewPrinciples = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.view-principles-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.view-principles" />
    </Link>
  );

  const moreMasterCardInfo = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.more-mastercard-info-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.more-mastercard-info" />
    </Link>
  );

  const visitContactUs = (
    <Link to="/contact-us/capitalonecards/" target="_blank" rel="noopener">
      <FormattedMessage id="pages.about.consumer-advocacy.visit-contact-us" />
    </Link>
  );

  const reviewComplaintHandlingProcess = (
    <Link to="/contact-us/concerns/" target="_blank" rel="noopener">
      <FormattedMessage id="pages.about.consumer-advocacy.review-complaint-handling-process" />
    </Link>
  );

  const visitCBA = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.visit-cba-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.visit-cba" />
    </Link>
  );

  const readBrochure = (
    <Link
      to={intl.formatMessage({ id: 'pages.about.consumer-advocacy.read-brochure-link' })}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage id="pages.about.consumer-advocacy.read-brochure" />
    </Link>
  );

  const complaintHandlingProcess = (
    <Link to="/contact-us/concerns/" target="_blank" rel="noopener">
      <FormattedMessage id="pages.about.consumer-advocacy.complaint-handling-process" />
    </Link>
  );

  const bankAct = (
    <em>
      <FormattedMessage id="pages.about.consumer-advocacy.bank-act" />
    </em>
  );

  const preferentialPricing = (
    <strong>
      <FormattedMessage id="pages.about.consumer-advocacy.preferential-pricing" />
    </strong>
  );
  const bundlingProducts = (
    <strong>
      <FormattedMessage id="pages.about.consumer-advocacy.bundling-products" />
    </strong>
  );

  const not1 = (
    <strong>
      <FormattedMessage id="pages.about.consumer-advocacy.not1" />
    </strong>
  );
  const not2 = (
    <strong>
      <FormattedMessage id="pages.about.consumer-advocacy.not2" />
    </strong>
  );

  return {
    codeOfConductCommitments: (
      <>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph1" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.code-of-conduct-commitments.sub-title1" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph2"
            values={{ viewCode }}
          />
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph9"
            values={{ seniorsCode }}
          />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.code-of-conduct-commitments.sub-title2" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph3"
            values={{ viewCode }}
          />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.code-of-conduct-commitments.sub-title3" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph4"
            values={{ viewCommitment }}
          />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.code-of-conduct-commitments.sub-title4" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph5"
            values={{ viewCommitment }}
          />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.code-of-conduct-commitments.sub-title5" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph6"
            values={{ viewPrinciples }}
          />
        </p>
        <p>
          <strong>
            <FormattedMessage
              id="pages.about.consumer-advocacy.code-of-conduct-commitments.sub-title6"
              values={{ md: superScript('md'), rball: superScript('rball') }}
            />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph7"
            values={{ moreMasterCardInfo }}
          />
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.code-of-conduct-commitments.paragraph8"
            values={{ visitContactUs, reviewComplaintHandlingProcess }}
          />
        </p>
      </>
    ),
    powersOfAttorneySetup: (
      <>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.power-of-attorney-setup.paragraph1"
            values={{ visitCBA }}
          />
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.power-of-attorney-setup.paragraph2"
            values={{ readBrochure }}
          />
        </p>
        {createList({
          prefix: 'pages.about.consumer-advocacy.power-of-attorney-setup.bullet',
          size: 4,
          isNumerical: false,
          siblings: {
            1: createList({
              prefix: 'pages.about.consumer-advocacy.power-of-attorney-setup.bullet1-list.point',
              size: 7,
              isNumerical: false,
            }),
            2: createList({
              prefix: 'pages.about.consumer-advocacy.power-of-attorney-setup.bullet2-list.point',
              size: 3,
              isNumerical: false,
            }),
            3: createList({
              prefix: 'pages.about.consumer-advocacy.power-of-attorney-setup.bullet3-list.point',
              size: 4,
              isNumerical: false,
            }),
          },
        })}
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.power-of-attorney-setup.send-documents-to.title" />
          </strong>
          <div>
            <strong>
              <FormattedMessage id="pages.about.consumer-advocacy.power-of-attorney-setup.send-documents-to.address-to" />
            </strong>
          </div>
          <div>
            <FormattedMessage id="pages.about.consumer-advocacy.power-of-attorney-setup.send-documents-to.address-line1" />
          </div>
          <div>
            <FormattedMessage id="pages.about.consumer-advocacy.power-of-attorney-setup.send-documents-to.address-line2" />
          </div>
        </p>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.power-of-attorney-setup.paragraph3" />
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.power-of-attorney-setup.paragraph4"
            values={{ complaintHandlingProcess }}
          />
        </p>
      </>
    ),
    aboutProhibitedConduct: (
      <>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.sub-title1" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph1"
            values={{ bankAct }}
          />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.sub-title2" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph2"
            values={{ bankAct }}
          />
        </p>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.section1" />
        </p>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.section2" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.sub-title3" />
          </strong>
        </p>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph3" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.sub-title4" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph4.text"
            values={{ preferentialPricing, bundlingProducts, bankAct }}
          />
        </p>
        <ul>
          <li>
            <p>
              <strong>
                <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph4.bullet1.text" />
              </strong>
            </p>
            <div>
              <p>
                <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph4.bullet1.paragraph1" />
              </p>
            </div>
          </li>
          <li>
            <p>
              <strong>
                <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph4.bullet2.text" />
              </strong>
            </p>
            <div>
              <p>
                <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph4.bullet2.paragraph1" />
              </p>
            </div>
          </li>
        </ul>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.sub-title5" />
          </strong>
        </p>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph5" />
        </p>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph6" />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.sub-title6" />
          </strong>
        </p>
        <p>
          <FormattedMessage
            id="pages.about.consumer-advocacy.about-prohibited-conduct.paragraph7"
            values={{ visitContactUs, reviewComplaintHandlingProcess }}
          />
        </p>
        <p>
          <a
            href={withPrefix(
              intl.formatMessage({
                id: 'pages.about.consumer-advocacy.about-prohibited-conduct.prohibited-conduct-pdf-link',
              }),
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="pages.about.consumer-advocacy.about-prohibited-conduct.prohibited-conduct-pdf" />
          </a>
        </p>
      </>
    ),
    CDICNotice: (
      <>
        <p>
          <FormattedMessage id="pages.about.consumer-advocacy.cdic-notice.paragraph1" values={{ bankAct }} />
        </p>
        <p>
          <strong>
            <FormattedMessage id="pages.about.consumer-advocacy.cdic-notice.notice" />
          </strong>
          <div>
            <FormattedMessage id="pages.about.consumer-advocacy.cdic-notice.deposits-with" />
          </div>
          <div>
            <FormattedMessage id="pages.about.consumer-advocacy.cdic-notice.name" />
          </div>
          <div>
            <FormattedMessage id="pages.about.consumer-advocacy.cdic-notice.not-insured" values={{ not1, not2 }} />
          </div>
          <div>
            <FormattedMessage id="pages.about.consumer-advocacy.cdic-notice.CDIC" />
          </div>
        </p>
      </>
    ),
  };
};
